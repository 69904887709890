*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.mountains-layer img, .land-layer img, .ogrecave-layer img, .ogre-layer img, .ogreslogo-layer img, .rocks-layer img, .sky-layer img, .cloudclub-layer img, .bats-layer img, .fog-layer img, .upperfog-layer img{
  width: 100%;
}
.mountains-layer {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 67%;
}
.land-layer {
  position: absolute;
  width: 100%;
  bottom: -4%;
  left: 50%;
  transform: translateX(-50%);
}
.ogrecave-layer {
  position: absolute;
  width: 95%;
  top: 24%;
}
.ogre-layer {
  position: absolute;
  width: 11%;
  top: 57%;
  right: 38%;
  z-index: 2;
  animation: ogre 5s ease;
  -webkit-animation: ogre 5s ease;
}
@keyframes ogre{
  0%{
    opacity: 0.1;
    top: 66%;
    right: 30%;
    transform: scale(0.4);
  }
  100%{
    opacity: 1;
  }
}
.ogreslogo-layer {
  position: absolute;
  width: 60%;
  /* left: 20%;
  top: 23%; */
  z-index: 3;
  animation: logo 1s linear;
}
@keyframes logo{
  0%{
    opacity: 0;
    transform: scale(0);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
.rocks-layer {
  position: absolute;
  width: 33%;
  right: 17%;
  top: 65%;
  z-index: 3;
}
.sky-layer {
  position: absolute;
  width: 100%;
  z-index: -1;
}
.cloudclub-layer {
  position: absolute;
  width: 108%;
  top: -80%;
  left: 0%;
  transform: translateX(-50%);
  z-index: -1;
  animation: animate-1 90s linear infinite;
  -webkit-animation: animate-1 90s linear infinite;
}
@keyframes animate-1 {
  from {
    left: 50%;
  }
  to {
    left: 150%;
  }
}
.bats-layer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0%;
}
.fog-layer {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  animation: animate-fog 30s ease-in-out infinite;
  -webkit-animation: animate-fog 30s ease-in-out infinite;
}
@keyframes animate-fog {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20%{
    opacity: 0.4;
    transform: scale(1.2);
  }
  40%{
    opacity: 0.9;
    transform: scale(1.4);
  }
  60%{
    opacity: 0.4;
    transform: scale(1);
  }
  80%{
    opacity: 0.9;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.upperfog-layer {
  position: absolute;
  width: 130%;
  z-index: -1;
  left: -10%;
}

.mbl-view{
  display: none;
}

/* @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .land-layer img {
      height: 250px;
    }
} */



@media only screen and (min-width: 320px) and (max-width: 600px) {
  @keyframes mbl-ogre{
    0%{
      opacity: 0.1;
      left: -25%;
      transform: scale(0.4);
    }
    100%{
      opacity: 1;
    }
  }
  .mbl-view{
    display: block;
  }
  .web-view{
    display: none;
  }
  .ogre-layer {
    width: 90%;
    top: auto;
    left: -10%;
    bottom: -6%;
    animation: mbl-ogre 1s linear;
  -webkit-animation: mbl-ogre 1s linear;
  }
  .ogreslogo-layer {
    width: 75%;
    left: 15%;
    top: 18%;
  }
  .land-layer{
    width: 200%;
    z-index: 0;
    bottom: -1%;
  }
  .rocks-layer{
    top: auto;
    bottom: 14%;
    z-index: 1;
    width: 40%;
    right: -18%;
  }
  .fog-layer{
    z-index: 1;
    top: 72%;
  }
  .ogrecave-layer {
    top: auto;
    bottom: 17%;
    z-index: 1;
    width: 78%;
    right: -6%;
  }
  .mountains-layer {
    top: 73%;
    z-index: 1;
    height: 100%;
  }
  .bats-layer {
    bottom: 26%;
    z-index: 1;
  }
  .sky-layer {
    width: 280%;
  }
  .upperfog-layer {
    z-index: 1;
    top: 17%;
    opacity: 0.8;
    width: 120%;
    left: -8%;
  }
  .cloudclub-layer {
    width: 100%;
    top: -8%;
    left: 50%;
  }
  .land-layer img {
    height: 50vh;
  }
}